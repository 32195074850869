import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from 'lodash';

export interface OauthExternalConfig {
  id: string;
  name: string;
  isEnabled: 'true' | 'false';
}

export interface AppConfig {
  firebaseConfig: Dictionary<any>;
  oauthConfig: {
    identityUrl: string;
    clientId: string;
    clientSecret: string;
  };
  oauthFlynexConfig: OauthExternalConfig;
  urlProtocol: string;
  emailEndpoint: string;
  emailSender: string;
  enableLogger?: 'true' | 'false';
  enableArchives?: 'true' | 'false';
  privacyPolicyUrl?: string;
  userGuideUrl?: string;
  firstNetAppCatalogLogo?: 'true' | 'false';
  version?: string;
  dynamicLinksUrlPrefix?: string;
  iosBundleId?: string;
  iosAppStoreId?: string;
  iosCustomScheme?: string;
  apiKey?: string;
  restApiUrl: string;
  identityServerUrl: string;
  janusServerUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private appConfig: AppConfig | undefined;

  constructor(private http: HttpClient) { }

  public get firebaseConfig() {
    return this.appConfig!.firebaseConfig;
  }

  public get oauthConfig() {
    return this.appConfig!.oauthConfig;
  }

  public get oauthFlynexConfig() {
    return this.appConfig!.oauthFlynexConfig;
  }

  public get emailEndpoint() {
    return this.appConfig!.emailEndpoint;
  }

  public get emailSender() {
    return this.appConfig!.emailSender;
  }

  public get urlProtocol() {
    return this.appConfig!.urlProtocol;
  }

  public get loggerEnabled() {
    return this.appConfig!.enableLogger === 'true';
  }

  public get enableArchives() {
    return this.appConfig!.enableArchives === 'true';
  }

  public get privacyPolicyUrl() {
    return this.appConfig!.privacyPolicyUrl;
  }

  public get userGuideUrl() {
    return this.appConfig!.userGuideUrl;
  }

  get firstNetAppCatalogLogo() {
    return this.appConfig!.firstNetAppCatalogLogo === 'true';
  }

  public get version() {
    return this.appConfig!.version;
  }

  public get dynamicLinksUrlPrefix() {
    return this.appConfig!.dynamicLinksUrlPrefix;
  }

  public get iosBundleId() {
    return this.appConfig!.iosBundleId;
  }

  public get iosAppStoreId() {
    return this.appConfig!.iosAppStoreId;
  }

  public get iosCustomScheme() {
    return this.appConfig!.iosCustomScheme;
  }

  public get apiKey() {
    return this.appConfig!.apiKey;
  }

  private _orgBaseMuxerServiceURL: string = '';
  public changeOrgBaseMuxerServiceURL(orgBaseMuxerServiceURL: string) {
    this._orgBaseMuxerServiceURL = orgBaseMuxerServiceURL;
    console.log('Organization baseMuxerServiceURL updated: ', orgBaseMuxerServiceURL);
  }

  public get restApiUrl(): string {
    const defaultURL = this.appConfig!.restApiUrl;
    const orgBaseMuxerServiceURL = this._orgBaseMuxerServiceURL;

    try {
      let finalURL = new URL(orgBaseMuxerServiceURL);
      finalURL.pathname = 'muxers/';
      return finalURL.toString();
    } catch (error) {
      console.error('Invalid organization baseMuxerServiceURL: ', orgBaseMuxerServiceURL);
      return defaultURL;
    }
  }

  public get identityServerUrl(): string {
    return this.appConfig!.identityServerUrl;
  }

  public get janusServerUrl(): string {
    return this.appConfig!.janusServerUrl;
  }

  async loadAppConfig() {
    const config = await this.http.get<AppConfig>('assets/config/config.json').toPromise();
    this.appConfig = config;
  }
}
